
@import url(//fonts.googleapis.com/css?family=Chonburi);
h1 {
  font-size: 40px;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-weight: normal;
  font-family: 'Chonburi', cursive;
}
/* .menu-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color:  rgb(66, 0, 0);
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  border: none;
  outline: none;
}

.menu-button:hover {
  background-color: #000000;
} */
.gif-container {
  max-width: 100%;
}

.gif-container img {
  width: 100%;
  height: auto;
}
button {
  border: none;
  padding: 8px 10px;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #000;
  color: #fff;
  
}


h2 {
  font-size: 30px;
  margin-top: 40px;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
}

.menu {
  max-width: 800px;
  margin: 0 auto;
  font-family: Georgia, serif;
  text-align: center;
  border: 15px solid rgb(66, 0, 0);
  padding: 30px;
   background-color: #fffefc;
}

p {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 20px;
  color: rgb(88, 88, 88);
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  margin-bottom: 40px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
  text-align: left;
}

li:last-child {
  border-bottom: none;
}

.price {
  font-size: 28px;
  color: #a61e19;
  font-weight: bold;
  margin-top: 20px;
}

.special {
  background-color: #a61e19;
  color: #fff;
  padding: 5px 10px;
  font-size: 20px;
  display: inline-block;
  margin-bottom: 20px;
  border-radius: 5px;
}
